import React, { Component } from "react";
import { Link } from "gatsby";
import { AboutOurAds } from "@npm/react-component-library";

// import components
import SEO from "../components/seo";
import Layout from "../components/Layout/Layout.component";

class AboutOurAdsComponent extends Component {
  render() {
    return (
      <Layout
          metaTitle="About Our Ads | Gallo Salame®"
          metaDescription="Information about how we use tracking technologies and how to opt out."
      >
        <div>
          <AboutOurAds
            className="px-4 px-xl-5 gagl-legal-pages gagl-nav-top-space"
            privacyPolicyLink={<Link to="/about-our-ads/">ABOUT OUR ADS</Link>}
            website="https://www.tyson.com/"
          />
        </div>
      </Layout>
    );
  }
}

export default AboutOurAdsComponent;
